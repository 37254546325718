import classnames from "classnames";
import { getAsyncRequest, postAsyncRequest, useUserState } from "gx-npm-lib";
import { publish } from "gx-npm-messenger-util";
import { Fragment, useContext, useEffect, useMemo } from "react";
import * as reactDeviceDetect from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { OnboardingUser } from "../../../app.constants";
import { AppContext } from "../../../app.context";
import { useGxtmParams } from "../../../hooks/gxtm-params.hook";
import { constructValidRedirect } from "../../../lib";
import MobileWarningOverlayComponent from "../../../ui/mobile-warning-overlay/mobile-warning-overlay.component";
import OnboardingOverlayComponent from "../../../ui/onboarding-overlay/onboarding-overlay.component";
import FirstTimeLogin from "./first-time-login/first-time-login.component";
import FormHeader from "./form-header/form-header.component";
import LoginButton from "./login-button/login-button.component";
import LoginFormFields from "./login-form-fields/login-form-fields.component";
import LoginFormOptions from "./login-form-options/login-form-options.component";
import { LoginFormContext } from "./login-form.context";
import styles from "./login-form.module.scss";
import { GCOM_4480_GCOM_4482_gcomLogin } from "../../../lib/feature-flags";
import { useFeatureFlag } from "gx-npm-ui";

const LoginForm = () => {
  const { t } = useTranslation();
  const {
    redirectUrl,
    showMobileMessage,
    showOnboardingExperience,
    setIsHomeOnboardingRedirect,
    setOnboardingUser,
    setRedirectUrl,
    setShowMobileMessage,
    setShowOnboardingExperience,
  } = useContext(AppContext);
  const {
    email,
    errorText,
    isSuccessfulSso,
    password,
    remember,
    showSso,
    setErrorText,
    setIsLoading,
    setIsSuccessfulSso,
    setShowSso,
  } = useContext(LoginFormContext);
  const { hasError, hasInitiatives, hasTasks, hasUserState, isEntitled, isOnboarded } = useUserState();
  const { gxtmParams } = useGxtmParams();
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const redirect = queryParams.get("redirect");
  const loggedOut = queryParams.get("logout");
  const uriHash = redirect && location.hash ? location.hash : "";
  const isFFGCOM4482On = useFeatureFlag(GCOM_4480_GCOM_4482_gcomLogin);

  useEffect(() => {
    if (hasUserState || loggedOut) {
      return;
    }
    (async () => {
      const response = await getAsyncRequest(isFFGCOM4482On ? "api/v5/auth/init" : "api/v4/auth/init");
      if (response && response.status === 200) {
        setIsSuccessfulSso(true);
        publish("WS_CONNECT");
        publish("SESSION_EVENT_LOAD");
        publish("USER_STATE_LOAD");
      }
    })();
  }, [hasUserState, loggedOut, setIsSuccessfulSso, isFFGCOM4482On]);

  useEffect(() => {
    if (!hasUserState) {
      return;
    }
    const isSurveyRecipient = hasTasks && !hasInitiatives && !isEntitled;
    setOnboardingUser(isSurveyRecipient ? OnboardingUser.SURVEY_RECIPIENT : OnboardingUser.EVALUATOR);
  }, [hasInitiatives, hasTasks, hasUserState, isEntitled, setOnboardingUser]);

  useEffect(() => {
    if (!redirect) {
      return;
    }

    if (redirect.includes("home/onboarding")) {
      setIsHomeOnboardingRedirect(true);
    }
  }, [redirect, setIsHomeOnboardingRedirect]);

  useEffect(() => {
    if (isFFGCOM4482On && !isSuccessfulSso) {
      return;
    }
    if (!hasUserState) {
      return;
    }
    const isSurveyRecipient = hasTasks && !hasInitiatives && !isEntitled;
    let url = isSurveyRecipient ? "/s/tasks-dashboard" : "/s/home";
    if (gxtmParams) {
      url += "?" + gxtmParams;
    }
    if (redirect) {
      url = constructValidRedirect(redirect + uriHash);
    }
    setRedirectUrl(url);
    if (reactDeviceDetect.isMobile) {
      setShowMobileMessage(true);
      return;
    }
    if (!isOnboarded) {
      if (isSuccessfulSso) {
        setShowSso(true);
        return;
      }
      setShowOnboardingExperience(true);
    } else {
      navigateToUrl(url);
    }
  }, [
    isOnboarded,
    hasInitiatives,
    hasTasks,
    hasUserState,
    isSuccessfulSso,
    isEntitled,
    queryParams,
    redirect,
    setIsHomeOnboardingRedirect,
    setOnboardingUser,
    setRedirectUrl,
    setShowMobileMessage,
    setShowOnboardingExperience,
    setShowSso,
    t,
    uriHash,
    gxtmParams,
    isFFGCOM4482On,
  ]);

  useEffect(() => {
    if (hasError) {
      setErrorText(t("Oops... something went wrong. Please try again.") as string);
    }
  }, [hasError, setErrorText, t]);

  const handleSubmitForm = async () => {
    if (!email || !password) {
      setErrorText(t("Please fill out all fields.") as string);
    } else {
      setErrorText("");
      const config = { withCredentials: true };
      const load = { username: email, password, remember };
      const url = isFFGCOM4482On
        ? `api/v4/auth/login${gxtmParams ? `?${gxtmParams}` : ""}`
        : `api/v3/auth/login${gxtmParams ? `?${gxtmParams}` : ""}`;
      setIsLoading(true);
      const response = await postAsyncRequest(url, load, config);
      setIsLoading(false);

      if ([200].includes(response.status)) {
        if (isFFGCOM4482On) {
          setIsSuccessfulSso(true);
        }
        publish("WS_CONNECT");
        publish("USER_STATE_LOAD");
        publish("SESSION_EVENT_LOAD");
      } else if ([400, 401, 422].indexOf(response.status) > -1) {
        setErrorText(t("The email or password is not correct.") as string);
      } else if (response.status === 403 && response.data?.message === "NOT_ENTITLED") {
        const error = t("This account does not have permission to access Gartner BuySmart.");
        const advice = t("Please contact your Gartner Account Manager for assistance.");
        setErrorText(`${error} ${advice}`);
      } else if (response.status === 403 && response.data?.message === "FREE_TRIAL_EXPIRED") {
        setErrorText(t("Your free trial period has expired.") as string);
      } else {
        setErrorText("Oops... something went wrong. Please try again.");
      }
    }
  };

  const handleMobileMessageClose = () => {
    if (!isOnboarded) {
      setShowOnboardingExperience(true);
    } else {
      navigateToUrl(redirectUrl);
    }
  };

  return (
    <Fragment>
      <div className={styles.loginForm}>
        {showSso && <FirstTimeLogin />}
        {!showSso && (
          <Fragment>
            <FormHeader />
            <form aria-label={t("login form")} noValidate autoComplete="off">
              <LoginFormFields onSubmit={handleSubmitForm} />
              <LoginFormOptions />
              <div className={classnames(styles.loginBtnWrapper, errorText && styles.buttonWithError)}>
                <LoginButton disabled={!email || !password} onSubmit={handleSubmitForm} />
              </div>
            </form>
          </Fragment>
        )}
      </div>
      <MobileWarningOverlayComponent onClose={handleMobileMessageClose} isOpen={showMobileMessage} />
      <OnboardingOverlayComponent isOpen={showOnboardingExperience} />
    </Fragment>
  );
};
export default LoginForm;
