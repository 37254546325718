import { getAsyncRequest } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTokenParam from "../../../hooks/token-param.hook";
import SignUpButton from "./sign-up-form-button/sign-up-form-button.component";
import SignUpFormFields from "./sign-up-form-fields/sign-up-form-fields.component";
import SignUpFormHeader from "./sign-up-form-header/sign-up-form-header.component";
import SignUpFormOptions from "./sign-up-form-options/sign-up-form-options.component";
import SignUpProfileFields from "./sign-up-profile-fields/sign-up-profile-fields.component";
import { SignUpFormContext } from "./sign-up-form.context";
import styles from "./sign-up-form-v2.styles.module.scss";
import classNames from "classnames";

const SignUpFormV2Component = () => {
  const { t } = useTranslation();
  const { hasEvaluatedToken, isValidToken, surveyId } = useTokenParam();
  const { page, setErrorText, setGcomData } = useContext(SignUpFormContext);
  const [showDivider, setShowDivider] = useState(true);

  useEffect(() => {
    if (!hasEvaluatedToken || !isValidToken || surveyId) {
      return;
    }
    (async () => {
      try {
        const response = await getAsyncRequest("/api/v4/data/profile");
        if (response?.status !== 200 || !response.data?.data) {
          throw new Error("Failed to fetch profile data");
        }
        setGcomData({
          companySize: response.data.data.companySize || [],
          functions: response.data.data.functions || [],
          industries: response.data.data.industries || [],
          countries: response.data.data.countries,
        });
      } catch (error) {
        setErrorText("Failed to fetch profile data. Please try again.");
      }
    })();
  }, [hasEvaluatedToken, isValidToken, surveyId, setErrorText, setGcomData]);

  return (
    <div className={classNames(styles.signUpForm)}>
      <SignUpFormHeader />
      <form noValidate autoComplete="off" className={styles.form}>
        {page === 1 ? <SignUpFormFields /> : <SignUpProfileFields setShowDivider={setShowDivider} />}

        {page === 2 && showDivider && <div className={styles.divider} />}
        <div className={page === 2 ? styles.page2Footer : ""}>
          <TypographyComponent
            boldness="regular"
            color="poisonCherry"
            styling="p4"
            rootClassName={page === 1 ? styles.page1RequiredText : ""}
          >
            {t("*Required")}
          </TypographyComponent>
          {page === 1 && <SignUpFormOptions />}
          <SignUpButton />
        </div>
      </form>
    </div>
  );
};

export default SignUpFormV2Component;
