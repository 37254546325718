import classNames from "classnames";
import { commonTheme } from "gx-npm-common-styles";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { Button, TypographyComponent, TypographyStyling } from "gx-npm-ui";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigateToUrl } from "single-spa";
import { ClientEvent, ClientEventOnboardingStep, OnboardingSteps, OnboardingUser } from "../../../app.constants";
import { AppContext } from "../../../app.context";
import styles from "./left-side-text.styles.module.scss";

const ANIMATION_DELAY = 500;
const stepOrder = [OnboardingSteps.STEP_ONE, OnboardingSteps.STEP_TWO, OnboardingSteps.STEP_THREE];
type TypographyStyles = Extract<TypographyStyling, "h1" | "h2" | "h3" | "h4" | "p1" | "p2" | "p3">;

const LeftSideText = () => {
  const { t } = useTranslation();
  const { step, setStep, onboardingUser, signUpUrl, eventId, isHomeOnboardingRedirect, redirectUrl } =
    useContext(AppContext);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [titleStyling, setTitleStyling] = useState<TypographyStyles>("h1");
  const [subtitleStyling, setSubtitleStyling] = useState<TypographyStyles>("p1");
  const captureEvents = useCaptureEventsV2();

  const steps =
    onboardingUser === OnboardingUser.EVALUATOR
      ? [
          {
            title: t("Welcome to Gartner BuySmart"),
            description: t(
              "BuySmart helps you streamline your team’s path to better technology decisions—fueled by industry‑leading Gartner insights."
            ),
          },
          {
            title: t("Get help when you need it"),
            description: t(
              "Get up and running with our quick-start guides, designed to provide a high level overview of key features for instant productivity."
            ),
          },
          {
            title: t("Dive deeper with our knowledge base"),
            description: t(
              "Access relevant content, get answers to common questions and learn more about how features work with our knowledge base."
            ),
          },
        ]
      : [
          {
            title: t("A team evaluating new technology is interested in learning more about your product."),
            description: t(
              "With BuySmart, you can respond to questions, upload requested documents, and then share those responses in real time with the evaluation team."
            ),
          },
        ];

  const handleResize = useCallback(() => {
    const { xl, xxl } = commonTheme.breakpoints.values;
    const isSurveyRecipient = onboardingUser === OnboardingUser.SURVEY_RECIPIENT;
    const width = window.innerWidth;

    if (width < xl) {
      setSubtitleStyling("p3");
      setTitleStyling(isSurveyRecipient ? "h4" : "h3");
    } else if (width < xxl) {
      setSubtitleStyling(isSurveyRecipient ? "p1" : "p2");
      setTitleStyling(isSurveyRecipient ? "h3" : "h2");
    } else {
      setSubtitleStyling("p1");
      setTitleStyling(isSurveyRecipient ? "h2" : "h1");
    }
  }, [onboardingUser]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    if (isTransitioning) {
      const timeoutId = setTimeout(handleTransitionEnd, ANIMATION_DELAY);
      return () => {
        window.removeEventListener("resize", handleResize);
        clearTimeout(timeoutId);
      };
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isTransitioning, handleResize]);

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const handleNext = () => {
    if (onboardingUser === OnboardingUser.EVALUATOR) {
      setIsTransitioning(true);
      const timeoutId = setTimeout(() => {
        setStep((currentStep) =>
          currentStep === OnboardingSteps.STEP_ONE ? OnboardingSteps.STEP_TWO : OnboardingSteps.STEP_THREE
        );
        clearTimeout(timeoutId);
      }, ANIMATION_DELAY);
      captureEvents([
        {
          eventType: ClientEvent.LOGIN_USER_ONBOARDING,
          metaData: {
            step:
              step === OnboardingSteps.STEP_ONE
                ? ClientEventOnboardingStep.STEP_TWO
                : ClientEventOnboardingStep.STEP_THREE,
            userType: onboardingUser,
            eventId,
          },
        },
      ]);
    } else {
      captureEvents([
        {
          eventType: ClientEvent.LOGIN_USER_ONBOARDING,
          metaData: { step: ClientEventOnboardingStep.FINISH, userType: onboardingUser, eventId },
        },
      ]);
      navigateToUrl(redirectUrl);
    }
  };

  const handleBack = () => {
    if (onboardingUser === OnboardingUser.EVALUATOR) {
      setIsTransitioning(true);
      const timeoutId = setTimeout(() => {
        setStep((currentStep) =>
          currentStep === OnboardingSteps.STEP_THREE ? OnboardingSteps.STEP_TWO : OnboardingSteps.STEP_ONE
        );
        clearTimeout(timeoutId);
      }, ANIMATION_DELAY);
      captureEvents([
        {
          eventType: ClientEvent.LOGIN_USER_ONBOARDING,
          metaData: {
            step:
              step === OnboardingSteps.STEP_TWO ? ClientEventOnboardingStep.START : ClientEventOnboardingStep.STEP_TWO,
            userType: onboardingUser,
            eventId,
          },
        },
      ]);
    }
  };

  const handleFinish = () => {
    captureEvents([
      {
        eventType: ClientEvent.LOGIN_USER_ONBOARDING,
        metaData: { step: ClientEventOnboardingStep.FINISH, userType: onboardingUser, eventId },
      },
    ]);
    navigateToUrl(redirectUrl || signUpUrl);
  };

  return (
    <div className={styles.leftSideContainer}>
      <div className={styles.leftSideContent}>
        {onboardingUser === OnboardingUser.EVALUATOR && (
          <div className={styles.progressIndicator}>
            {steps.map((stepObj) => {
              const stepIndex = steps.indexOf(stepObj);
              const stepClasses = classNames(styles.step, {
                [styles.previousStep]: stepIndex < stepOrder.indexOf(step),
                [styles.activeStep]: stepIndex === stepOrder.indexOf(step),
              });
              return <div key={stepObj.title} className={stepClasses} />;
            })}
          </div>
        )}
        <TypographyComponent
          rootClassName={classNames(
            styles.title,
            onboardingUser === OnboardingUser.SURVEY_RECIPIENT && styles.surveyRecipient,
            {
              [styles.fadeOut]: isTransitioning,
              [styles.fadeIn]: !isTransitioning,
            }
          )}
          color="carbon"
          boldness={"medium"}
          styling={titleStyling}
          element={"h1"}
        >
          {steps[stepOrder.indexOf(step)].title}
        </TypographyComponent>
        <TypographyComponent
          rootClassName={classNames(styles.description, {
            [styles.fadeOut]: isTransitioning,
            [styles.fadeIn]: !isTransitioning,
          })}
          styling={subtitleStyling}
          color="coal"
        >
          {steps[stepOrder.indexOf(step)].description}
        </TypographyComponent>
        <div className={styles.leftSideButtons}>
          <Button
            onClick={handleBack}
            rootClassName={classNames(styles.backBtn, "btn-secondary", {
              [styles.visible]: step !== OnboardingSteps.STEP_ONE,
              [styles.fadeOut]: isTransitioning && step === OnboardingSteps.STEP_ONE,
              [styles.fadeIn]: !isTransitioning && step !== OnboardingSteps.STEP_ONE,
            })}
          >
            {t("Back")}
          </Button>
          <Button
            onClick={handleNext}
            rootClassName={classNames(styles.contBtn, "btn-primary", {
              [styles.visible]: step !== OnboardingSteps.STEP_THREE,
              [styles.fadeOut]: isTransitioning && step === OnboardingSteps.STEP_TWO,
              [styles.fadeIn]: !isTransitioning || step !== OnboardingSteps.STEP_TWO,
              [styles.contBtnStep2]: step === OnboardingSteps.STEP_TWO,
            })}
          >
            {onboardingUser === OnboardingUser.EVALUATOR ? t("Continue") : t("Got it")}
          </Button>
          <Button
            onClick={handleFinish}
            rootClassName={classNames(styles.finishBtn, "btn-primary", {
              [styles.visible]: step === OnboardingSteps.STEP_THREE,
              [styles.fadeOut]: isTransitioning && step !== OnboardingSteps.STEP_THREE,
              [styles.fadeIn]: !isTransitioning || step === OnboardingSteps.STEP_THREE,
            })}
          >
            {isHomeOnboardingRedirect ? t("Continue") : t("Finish")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeftSideText;
