import { Select, TextField, TypographyComponent } from "gx-npm-ui";
import { ChangeEvent, Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignUpFormContext } from "../sign-up-form.context";
import { CompanySize, Industry, ProfileFunction, Role, State } from "../sign-up-form.types";
import styles from "./sign-up-profile-fields.styles.module.scss";
import classNames from "classnames";
import AutocompleteComponent from "../../../../ui/autocomplete/autocomplete.component";
import { getAsyncRequest } from "gx-npm-lib";

const SignUpProfileFields = ({ setShowDivider }: { setShowDivider?: (value: boolean) => void }) => {
  const { t } = useTranslation();
  const { setIsCompleteProfileForm, profile, setProfile, gcomData, setErrorText } = useContext(SignUpFormContext);
  const [selectedFunctionRoles, setSelectedFunctionRoles] = useState<Role[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [showStatesInput, setShowStatesInput] = useState(false);
  const countryDropdownOptions = gcomData.countries.map((country) => {
    return country.isBlockedCountry ? `[BLOCKED] ${country.countryName}` : country.countryName;
  });
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { companySize, industry, function: func, role, title, countryCode, state } = profile;
    if (
      companySize &&
      industry &&
      func &&
      role &&
      title &&
      countryCode &&
      (showStatesInput ? state?.stateCode : true)
    ) {
      setIsCompleteProfileForm(true);
    } else {
      setIsCompleteProfileForm(false);
    }
  }, [profile, setIsCompleteProfileForm, showStatesInput]);

  const handleChange = (event: ChangeEvent<{ value: string }> | ChangeEvent<HTMLInputElement>, inputType: string) => {
    const targetValue = (event.target as HTMLInputElement).value as string;
    let selectedOption: CompanySize | Industry | ProfileFunction | Role | undefined;

    switch (inputType) {
      case "companySize":
        selectedOption = gcomData.companySize.find((size) => size.name === targetValue);
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          companySize: selectedOption ? selectedOption.name : "",
          companySizeId: selectedOption ? selectedOption.id : null,
        }));
        break;
      case "industry":
        selectedOption = gcomData.industries.find((industry) => industry.name === targetValue);
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          industry: selectedOption ? selectedOption.name : "",
          industryId: selectedOption ? selectedOption.id : null,
        }));
        break;
      case "function":
        selectedOption = gcomData.functions.find((func) => func.name === targetValue);
        if (selectedOption) {
          setSelectedFunctionRoles((selectedOption as ProfileFunction).roles);
          setProfile((prevProfileData) => ({
            ...prevProfileData,
            function: selectedOption ? selectedOption.name : "",
            functionId: selectedOption ? selectedOption.id : null,
            role: "",
            roleId: null,
          }));
        }
        break;
      case "role":
        selectedOption = selectedFunctionRoles.find((role) => role.name === targetValue);
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          role: selectedOption ? selectedOption.name : "",
          roleId: selectedOption ? selectedOption.id : null,
        }));
        break;
      default:
        setProfile((prevProfileData) => ({
          ...prevProfileData,
          [inputType]: targetValue,
        }));
        break;
    }
  };

  const handleCountryChange = async (val: string) => {
    handleStateChange(null);
    const countryDetails = gcomData.countries.find((country) =>
      country.isBlockedCountry ? `[BLOCKED] ${country.countryName}` === val : country.countryName === val
    );
    const countryISOCode = countryDetails?.countryISOCode;
    const isStateRequired = countryDetails?.isStateMandatory;

    setProfile((prevProfileData) => ({
      ...prevProfileData,
      countryCode: countryISOCode || null,
    }));
    if (isStateRequired) {
      const response = await getAsyncRequest(`/api/v2/data/state/${countryISOCode}`);
      if (response.status === 200 && response.data?.data) {
        const { states: statesData } = response.data.data;
        setStates(statesData);
        setShowStatesInput(true);
        containerRef.current?.scroll({ behavior: "smooth", top: containerRef.current?.clientHeight });
      } else {
        setErrorText("Failed to fetch states data. Please try again.");
      }
    } else {
      setStates([]);
      setShowStatesInput(false);
      setProfile((prevProfileData) => ({
        ...prevProfileData,
        state: null,
      }));
    }
  };
  const handleStateChange = (val: string | null) => {
    const stateDetails = states.find((state) => state.stateName === val);
    const selectedCountry = gcomData.countries.find((country) => country.countryISOCode === profile.countryCode);
    if (stateDetails) {
      setProfile((prevProfileData) => ({
        ...prevProfileData,
        state: {
          isProvince: selectedCountry?.countryISOCode?.toUpperCase() !== "US",
          stateName: stateDetails.stateName,
          stateCode: stateDetails.stateISOCode,
        },
      }));
      setSelectedState(stateDetails.stateName);
    } else {
      setProfile((prevProfileData) => ({
        ...prevProfileData,
        state: null,
      }));
      setSelectedState(null);
    }
  };

  const handleScroll = (div: HTMLDivElement) => {
    const scrolledToBottom = div.scrollTop >= div.scrollHeight - div.offsetHeight;
    if (setShowDivider) {
      setShowDivider(!scrolledToBottom);
    }
  };

  return (
    <Fragment>
      <div
        aria-label={"profileContainer"}
        ref={containerRef}
        className={styles.profileContainer}
        onScroll={(e) => handleScroll(e.target as HTMLDivElement)}
      >
        <TypographyComponent styling={"p4"} color={"iron"} boldness={"medium"} rootClassName={styles.groupTitle}>
          {t("About you")}
        </TypographyComponent>
        <Select
          disabled={gcomData.functions?.length === 0}
          items={gcomData.functions.map((func) => func.name)}
          isRequired
          label={`${t("Function")}`}
          {...(gcomData.functions.length > 5 && { menuHeight: "210px" })}
          onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "function")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName={styles.userInput}
          borderRadius={"4px"}
        />
        <Select
          disabled={selectedFunctionRoles.length === 0}
          items={selectedFunctionRoles.map((role) => role.name)}
          isRequired
          label={`${t("Role")}`}
          {...(selectedFunctionRoles.length > 5 && { menuHeight: "210px" })}
          onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "role")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName={styles.userInput}
          borderRadius={"4px"}
        />
        <TextField
          label="Job title"
          placeholder={t("Job title")}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, "title")}
          rootClassName={classNames(styles.userInput, styles.jobTitle)}
          required
        />
        <TypographyComponent styling={"p4"} color={"iron"} boldness={"medium"} rootClassName={styles.groupTitle}>
          {t("About your company")}
        </TypographyComponent>
        <Select
          disabled={gcomData.companySize?.length === 0}
          items={gcomData.companySize.map((size) => size.name)}
          isRequired
          label={`${t("Company size")}`}
          {...(gcomData.companySize.length > 5 && { menuHeight: "210px" })}
          onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "companySize")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName={styles.userInput}
          borderRadius={"4px"}
        />
        <Select
          disabled={gcomData.industries?.length === 0}
          items={gcomData.industries.map((industry) => industry.name)}
          isRequired
          label={`${t("Industry")}`}
          {...(gcomData.industries.length > 5 && { menuHeight: "210px" })}
          onChange={(e: ChangeEvent<{ value: string }>) => handleChange(e, "industry")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName={styles.userInput}
          borderRadius={"4px"}
        />
        <AutocompleteComponent
          label={t("Country")}
          options={countryDropdownOptions}
          handleChange={(e: ChangeEvent, val: string) => handleCountryChange(val)}
          isRequired={true}
          listOptionBoldness={"regular"}
          rootClassName={classNames(styles.userInput)}
          value={
            gcomData.countries.find((country) => country.countryISOCode === profile.countryCode)?.countryName || null
          }
        />
        {showStatesInput && (
          <AutocompleteComponent
            label={t("State, province or region")}
            options={states.map((state) => state.stateName)}
            handleChange={(e: ChangeEvent, val: string) => handleStateChange(val)}
            isRequired={showStatesInput}
            rootClassName={classNames(styles.userInput)}
            listOptionBoldness={"regular"}
            value={selectedState}
          />
        )}
      </div>
    </Fragment>
  );
};

export default SignUpProfileFields;
